// import React, { useEffect } from 'react'
// import './css/welcome.css'
// // import { useNavigate } from 'react-router-dom'

// import { Unity, useUnityContext } from "react-unity-webgl";

// function Welcome() {
//   // const navigate = useNavigate();

//   // const { unityProvider } = useUnityContext({
//   //   loaderUrl: "Build/test.loader.js",
//   //   dataUrl: "Build/test.data",
//   //   frameworkUrl: "Build/test.framework.js",
//   //   codeUrl: "Build/test.wasm",
//   // });

//   useEffect(() => {
//     const requestMicrophonePermission = async () => {
//       try {
//         const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//         console.log("Microphone permission granted!");
//         // Unity 앱에서 마이크 사용 처리
//       } catch (error) {
//         console.error("Microphone permission denied:", error);
//       }
//     };

//     const microphone = document.createElement('script');
//     const micworklet = document.createElement('script');

//     microphone.src = "http://localhost:8060/microphone.js";
//     micworklet.src = "http://localhost:8060/mic-worklet-module.js";

//     microphone.onload = () => {
//       // 마이크 권한 요청
//       requestMicrophonePermission();
//     };

//     microphone.onerror = () => {
//       console.log('load error!');
//     };

//     document.body.appendChild(microphone);
//     document.body.appendChild(micworklet);
//   }, []);

//   return (

//     <div className="unity-view">
//       <Unity unityProvider={unityProvider} className='unity-app' />
//     </div>

//   )
// }

// export default Welcome



// import React, { useEffect } from 'react';
// import './css/welcome.css';

// import { Unity, useUnityContext } from "react-unity-webgl";

// function Welcome() {
//   useEffect(() => {
//     const loadMicrophoneScript = async () => {
//       try {
//         await new Promise((resolve, reject) => {
//           const microphone = document.createElement('script');
//           microphone.src = "http://localhost:8060/microphone.js";
//           microphone.onload = resolve;
//           microphone.onerror = reject;
//           document.body.appendChild(microphone);
//         });

//         console.log("Microphone script loaded.");
//         // 이제 마이크 스크립트가 로드되었으므로 Unity 앱과 관련된 작업을 수행할 수 있습니다.
//         // Unity 앱에서 마이크 사용 처리 등을 수행할 수 있습니다.


//       } catch (error) {
//         console.error("Error loading microphone script:", error);
//       }
//     };

//     loadMicrophoneScript();
//   }, []);

//   const { unityProvider } = useUnityContext({
//     loaderUrl: "Build/test.loader.js",
//     dataUrl: "Build/test.data",
//     frameworkUrl: "Build/test.framework.js",
//     codeUrl: "Build/test.wasm",
//     streamingAssetsUrl: "streamingassets",
//   });

//   return (
//     <div className="unity-view">
//       <Unity unityProvider={unityProvider} className='unity-app' />
//     </div>
//   );
// }

// export default Welcome;


import React, { useCallback, useEffect } from 'react'
import './css/welcome.css'
import { Unity, useUnityContext } from "react-unity-webgl";
import { useNavigate } from 'react-router-dom';

function Welcome() {

  const navigate = useNavigate();

  const { unityProvider, addEventListener, removeEventListener, sendMessage } = useUnityContext({
    loaderUrl: "Build/test.loader.js",
    dataUrl: "Build/test.data",
    frameworkUrl: "Build/test.framework.js",
    codeUrl: "Build/test.wasm",
  });

  const __logout = useCallback(() => {
    localStorage.removeItem('token')
    navigate('/')
    // window.location.href = 'http://localhost:3000/'
  }, [navigate])

  const tokentest = useCallback(() => {
    // const token = localStorage.getItem('token');
    sendMessage('ButtonScript', 'RecieveUnity', 'asdf4qwe')
  }, [sendMessage])

  useEffect(() => {
    addEventListener('TakeTokenFromReact', tokentest)
    addEventListener('ButtonClickLogout', __logout)
    return () => {
      removeEventListener('TakeTokenFromReact', tokentest)
      removeEventListener('ButtonClickLogout', __logout)
    }
  }, [addEventListener, removeEventListener, __logout, tokentest])


  return (

    <div className="unity-view">
      <Unity unityProvider={unityProvider} className='unity-app' />
    </div>

  )
}

export default Welcome