import React, { useCallback, useEffect, useState } from 'react'
import './css/login.css'
import { useNavigate } from 'react-router-dom'

function Login() {
  const navigate = useNavigate();

  const [guestOpen, setGuestOpen] = useState(false);

  const [userId, setUserId] = useState(undefined)
  const [userPassword, setUserPassword] = useState(undefined)

  const [guestGender, setGuestGender] = useState('man')
  const [guestBirthday, setGuestBirthday] = useState(undefined)
  const [guestAge, setGuestAge] = useState(0)

  const __doLogin = useCallback(() => {
    fetch('https://inthe2.dgworld.o-r.kr/user/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId,
        userPassword
      })
    })
      .then(res => res.json())
      .then(({ msg, token }) => {
        if (msg === 'True') {
          localStorage.setItem('token', token);
          alert('로그인에 성공하셨습니다.')
          navigate('/welcome')
          // window.location.href = 'https://inthe3.dgworld.n-e.kr/'
        } else {
          alert(msg)
        }
      })
      .catch(err => {
        console.log('err: 로그인 화면단 에러' + err)
      })
  }, [userId, userPassword])

  const __goGuest = useCallback(() => {
    setGuestOpen(false)

    fetch('https://inthe2.dgworld.o-r.kr/new/guest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        guestGender,
        guestBirthday,
        guestAge
      })
    })
      .then(res => res.json())
      .then(({ msg, token }) => {
        if (msg === 'True') {
          localStorage.setItem('token', token);
          alert('게스트 로그인에 성공하셨습니다.')
          navigate('/welcome')
          // window.location.href = 'https://inthe3.dgworld.n-e.kr/'
        } else {
          alert('로그인을 재시도 해주세요.')
        }
      })
      .catch(err => {
        console.log('err: Guest 에러' + err)
      })
  }, [setGuestOpen, guestGender, guestBirthday, guestAge])

  const __guestSkip = useCallback(() => {
    setGuestOpen(false)

    // https://inthe2.dgworld.o-r.kr/guest/skip
    fetch('https://inthe2.dgworld.o-r.kr/guest/skip', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(({ msg, token }) => {
        if (msg === 'True') {
          localStorage.setItem('token', token)
          alert('게스트 로그인에 성공하셨습니다.')
          navigate('/welcome')
          // window.location.href = 'https://inthe3.dgworld.n-e.kr/'
        } else {
          alert('로그인을 재시도 해주세요.')
        }
      })
      .catch(err => {
        console.log('err: guest-skip 에러' + err)
      })

  }, [])

  const checkOnlyOne = (value) => {
    setGuestGender(value)
    // console.log(guestGender)
  }

  const __goJoin = useCallback(() => {
    navigate('/join')
    console.log('gitgubtest')
  }, [navigate])

  useEffect(() => {
    if (guestBirthday) {
      // console.log(guestBirthday)
      const today = new Date();
      const birthDate = guestBirthday.split('-');

      const age = today.getFullYear() - birthDate[0];
      const mounth = today.getMonth() - birthDate[1];

      if (mounth < 0 || (mounth === 0 && today.getDate() < birthDate[2])) {
        setGuestAge(age - 1);
      } else {
        setGuestAge(age);
      }
    }
  }, [guestBirthday]);

  return (
    <div className="login">
      <div className="wrapper">
        <div className="logo">
          Virtual Daegu
        </div>
        <form className="login-contents" onSubmit={(e) => {
          e.preventDefault();
          __doLogin();
        }}>
          <div className="login-contents-title">
            로그인
          </div>
          <div className="id-input custom-inp">
            <div className="title">
              아이디
            </div>
            <div className="inp">
              <input type="text" placeholder='아이디를 입력해주세요.'
                onChange={(e) => setUserId(e.target.value)} required />
            </div>
          </div>
          <div className="password-inp custom-inp">
            <div className="title">
              패스워드
            </div>
            <div className="inp">
              <input type="password" placeholder='패스워드를 입력해주세요.'
                onChange={(e) => setUserPassword(e.target.value)} required />
            </div>
          </div>
          <div className="id-find">
            {/* 아이디 / 패스워드 찾기 */}
          </div>
          <button className="login-btn" type='submit'>
            로그인
          </button>
        </form>
        <button className="go-join" onClick={__goJoin}>
          회원가입
        </button>
        <button className="guest-login" onClick={() => {
          setGuestOpen(true)
        }}>
          Guest
        </button>
        {
          guestOpen &&
          <div className="guest-background" >
            <div className="guest-window">
              <form className="guest-popup" onSubmit={(e) => {
                e.preventDefault()
                __goGuest()
              }}>
                <div className="guest-exit" onClick={() => {
                  setGuestOpen(false)
                }}>
                  ✖
                </div>
                <div className="guest-title">
                  Guest 정보입력
                </div>
                <div className="guest-content">
                  <div className="gender-inp custom-inp">
                    <div className="title">
                      성별
                    </div>
                    <div className="custom-checkbox">
                      <input
                        type="radio"
                        id="man"
                        name="user-gender"
                        value="man"
                        onChange={() => checkOnlyOne("man")}
                        checked={guestGender === "man"}
                      />
                      <label htmlFor="man">남자</label>
                    </div>
                    <div className="custom-checkbox">
                      <input
                        type="radio"
                        id="woman"
                        name="user-gender"
                        value="woman"
                        onChange={() => checkOnlyOne("woman")}
                        checked={guestGender === "woman"}
                      />
                      <label htmlFor="woman">여자</label>
                    </div>
                  </div>
                  <div className="birthday-inp custom-inp">
                    <div className="title">
                      생년월일
                    </div>
                    <div className="inp">
                      <input type="date" onBlur={(e) => {
                        setGuestBirthday(e.target.value)
                      }} required />
                    </div>
                  </div>
                </div>
                <div className="guest-popup-buttons">
                  <button className="guest-skip" onClick={__guestSkip}>
                    건너뛰기
                  </button>
                  <button className="guest-check" type='submit'>
                    확인
                  </button>
                </div>
              </form>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Login
