import React, { useCallback, useEffect, useState } from 'react'
import './css/join.css'
import { useNavigate } from 'react-router-dom';

function Join() {
  const navigate = useNavigate();

  const [termsOpen, setTermsOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);

  const [termsChecked, setTermsChecked] = useState(false)
  const [privacyChecked, setPrivacyChecked] = useState(false)

  const [isNickname, setIsNickname] = useState(false)
  const [isId, setIsId] = useState(false)
  const [isPassword, setIsPassword] = useState(false)

  const [userId, setUserId] = useState(undefined)
  const [userPassword, setUserPassword] = useState(undefined)
  const [userPassword2, setUserPassword2] = useState(undefined)
  const [userNickname, setUserNickname] = useState(undefined)
  const [userGender, setUserGender] = useState('man')
  const [userBirthday, setUserBirthday] = useState(undefined)
  const [userAge, setUserAge] = useState(0)

  const __createUser = useCallback(() => {
    if (!termsChecked || !privacyChecked) {
      alert('이용약관에 동의해주세요.')
      return
    }

    if (!isNickname) {
      alert('닉네임이 조건에 부합하지 않습니다.')
      return
    }

    if (!isId) {
      alert('아이디가 조건에 부합하지 않습니다.')
      return
    }

    if (!isPassword) {
      alert('비밀번호가 조건에 부합하지 않습니다.')
      return
    }

    fetch('https://inthe2.dgworld.o-r.kr/user/new', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId,
        userPassword,
        userPassword2,
        userNickname,
        userGender,
        userBirthday,
        userAge
      })
    })
      .then(res => res.json())
      .then(({ msg }) => {
        if (msg === 'True') {
          alert('회원가입이 완료되었습니다.')
          navigate('/')
          // navigate('/')
        } else {
          alert(msg)
        }
      })
      .catch(err => {
        console.log("회원가입 단 에러:" + err)
      })

  }, [userId, userPassword, userPassword2, userNickname, userGender, userBirthday,
    userAge, termsChecked, privacyChecked, isId, isNickname, isPassword, navigate])

  const checkOnlyOne = (value) => {
    setUserGender(value)
    // console.log(userGender)
  }

  const onChangeNickname = useCallback((e) => {
    const nicknameRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{2,10}$/
    const nicknameCurrent = e.target.value
    if (!nicknameRegex.test(nicknameCurrent)) {
      setIsNickname(false)
    } else {
      setIsNickname(true)
    }
  }, [])

  const onChangeId = useCallback((e) => {
    const idRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{5,10}$/
    const idCurrent = e.target.value
    if (!idRegex.test(idCurrent)) {
      setIsId(false)
    } else {
      setIsId(true)
    }
  }, [])

  const onChangePassword = useCallback((e) => {
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/
    const passwordCurrent = e.target.value
    if (!passwordRegex.test(passwordCurrent)) {
      setIsPassword(false)
    } else {
      setIsPassword(true)
    }
  }, [])

  useEffect(() => {
    if (userBirthday) {
      const today = new Date();
      const birthDate = userBirthday.split('-');

      const age = today.getFullYear() - birthDate[0];
      const mounth = today.getMonth() - birthDate[1];

      if (mounth < 0 || (mounth === 0 && today.getDate() < birthDate[2])) {
        setUserAge(age - 1);
      } else {
        setUserAge(age);
      }
    }
  }, [userBirthday]);

  return (
    <div className="join">
      <div className="wrapper">
        <div className="logo">
          Virtual Daegu
        </div>
        <form className="join-contents" onSubmit={(e) => {
          e.preventDefault()
          __createUser()
        }}>
          <div className="join-contents-title">
            회원가입
          </div>
          <div className="nickname-inp custom-inp">
            <div className="title">
              닉네임
            </div>
            <div className="inp">
              <input type="text" placeholder='2~10자의 한글, 영문, 숫자만 사용이 가능합니다.'
                onBlur={(e) => {
                  setUserNickname(e.target.value)
                }} onChange={(e) => { onChangeNickname(e) }} required />
            </div>
          </div>
          <div className="id-inp custom-inp">
            <div className="title">
              아이디
            </div>
            <div className="inp">
              <input type="text" placeholder='5~10자의 영문 소문자, 숫자만 사용이 가능합니다.'
                onBlur={(e) => {
                  setUserId(e.target.value)
                }} onChange={(e) => { onChangeId(e) }} required />
            </div>
          </div>
          <div className="password-inp custom-inp">
            <div className="title">
              패스워드
            </div>
            <div className="inp">
              <input type="password" placeholder='8~15자 영문 대 소문자, 숫자, 특수문자만 사용이 가능합니다.'
                onBlur={(e) => {
                  setUserPassword(e.target.value)
                }} onChange={(e) => { onChangePassword(e) }} required />
            </div>
          </div>
          <div className="password2-inp custom-inp">
            <div className="title">
              패스워드 확인
            </div>
            <div className="inp">
              <input type="password" placeholder='같은 패스워드를 입력해주세요'
                onBlur={(e) => {
                  setUserPassword2(e.target.value)
                }} required />
            </div>
          </div>

          <div className="gender-inp">
            <div className="title">
              성별
            </div>
            <div className="custom-checkbox">
              <input
                type="radio"
                id="man"
                name="user-gender"
                value="man"
                onChange={() => checkOnlyOne("man")}
                checked={userGender === "man"}
              />
              <label htmlFor="man">남자</label>
            </div>
            <div className="custom-checkbox">
              <input
                type="radio"
                id="woman"
                name="user-gender"
                value="woman"
                onChange={() => checkOnlyOne("woman")}
                checked={userGender === "woman"}
              />
              <label htmlFor="woman">여자</label>
            </div>
          </div>



          {/* <div className="gender-inp">
            <div className="title">
              성별
            </div>
            {genderOptions.map((option) => {
              return (
                <div key={option.value} className='inp'>
                  <input
                    className='gender-inp'
                    type="checkbox"
                    id={option.value}
                    name='guest-gender'
                    value={option.value}
                    onChange={() => checkOnlyOne(option.value)}
                    checked={userGender === option.value}
                  />
                  <label htmlFor={option.value}>
                    {option.label}
                  </label>
                </div>
              )
            })}
          </div> */}
          <div className="birthday-inp custom-inp">
            <div className="title">
              생년월일
            </div>
            <div className="inp">
              <input type="date" onBlur={(e) => {
                setUserBirthday(e.target.value)
              }} required />
            </div>
          </div>
          <div className="checkbox-agree-inp">
            <div className="terms">
              <input type="checkbox" id='terms-to-use' checked={termsChecked}
                onChange={() => setTermsChecked(!termsChecked)} />
              <label htmlFor="terms-to-use" onClick={() => {
                setTermsOpen(true)
              }}>
                이용약관 동의 ▶
              </label>
            </div>
            <div className="privacy">
              <input type="checkbox" id='privacy-agree' checked={privacyChecked}
                onChange={() => { setPrivacyChecked(!privacyChecked) }} />
              <label htmlFor="privacy-agree" onClick={() => {
                setPrivacyOpen(true)
              }}>
                개인정보동의 ▶
              </label>
            </div>
          </div>
          <button className="join-btn" type='submit'>
            회원가입
          </button>
        </form>
        {
          termsOpen &&
          <div className="terms-background" onClick={() => {
            setTermsOpen(false);
          }}>
            <div className="terms-window">
              <div className="terms-popup">
                <div className="terms-exit" onClick={() => {
                  setTermsOpen(false)
                }}>
                  ✖
                </div>
                <div className="terms-title">
                  이용약관
                </div>
                <div className="terms-content">
                  이어서 배경을 위한 CSS 코드를 작성해 보겠습니다. position fixed 속성을 사용하여 스크롤이 발생하더라도 화면에 고정되도록 하였고, width, height 를 이용하여 화면을 가득 차게 만들었습니다. 또한 배경의 어두운 정도는 0.3 으로 하였으나 이 부분은 원하시는 만큼 진하게 또는 연하게 하시면 될 것 같습니다. 마지막으로 z-index 를 이용해서 화면의 맨 앞으로 모달을 배치했습니다.
                  이어서 배경을 위한 CSS 코드를 작성해 보겠습니다. position fixed 속성을 사용하여 스크롤이 발생하더라도 화면에 고정되도록 하였고, width, height 를 이용하여 화면을 가득 차게 만들었습니다. 또한 배경의 어두운 정도는 0.3 으로 하였으나 이 부분은 원하시는 만큼 진하게 또는 연하게 하시면 될 것 같습니다. 마지막으로 z-index 를 이용해서 화면의 맨 앞으로 모달을 배치했습니다.
                </div>
                <div className="terms-check" onClick={() => {
                  setTermsOpen(false)
                }}>
                  확인
                </div>
              </div>
            </div>
          </div>
        }

        {
          privacyOpen &&
          <div className="privacy-background" onClick={() => {
            setPrivacyOpen(false);
          }}>
            <div className="privacy-window">
              <div className="privacy-popup">
                <div className="privacy-exit" onClick={() => {
                  setPrivacyOpen(false)
                }}>
                  ✖
                </div>
                <div className="privacy-title">
                  개인정보동의
                </div>
                <div className="privacy-content">
                  이어서 배경을 위한 CSS 코드를 작성해 보겠습니다. position fixed 속성을 사용하여 스크롤이 발생하더라도 화면에 고정되도록 하였고, width, height 를 이용하여 화면을 가득 차게 만들었습니다. 또한 배경의 어두운 정도는 0.3 으로 하였으나 이 부분은 원하시는 만큼 진하게 또는 연하게 하시면 될 것 같습니다. 마지막으로 z-index 를 이용해서 화면의 맨 앞으로 모달을 배치했습니다.
                  이어서 배경을 위한 CSS 코드를 작성해 보겠습니다. position fixed 속성을 사용하여 스크롤이 발생하더라도 화면에 고정되도록 하였고, width, height 를 이용하여 화면을 가득 차게 만들었습니다. 또한 배경의 어두운 정도는 0.3 으로 하였으나 이 부분은 원하시는 만큼 진하게 또는 연하게 하시면 될 것 같습니다. 마지막으로 z-index 를 이용해서 화면의 맨 앞으로 모달을 배치했습니다.
                </div>
                <div className="privacy-check" onClick={() => {
                  setPrivacyOpen(false)
                }}>
                  확인
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Join
