import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom'
import './styles/core.css'
import reportWebVitals from './reportWebVitals';
import Login from './component/Login/Login';
import Join from './component/Join/Join';
import Welcome from './component/Welcome/Welcome';
import { jwtDecode } from 'jwt-decode';

// const isAuthenticated = () => {
//   const token = localStorage.getItem('token');

//   if (!token) {
//     alert('다시 로그인을 시도해주세요')
//     return false
//   }

//   try {
//     const decodedToken = jwtDecode(token)

//     const isTokenExpired = decodedToken.exp < Date.now() / 1000;

//     if (isTokenExpired) {
//       localStorage.removeItem('token');
//       alert('로그인 시간이 만료되었습니다. 다시 로그인을 시도해주세요.')

//       return false
//     }

//   } catch (error) {
//     alert('다시 로그인을 시도해주세요')
//     console.error('유효하지 않은 토큰', error)
//     return false
//   }
//   return true
// }

// const PrivateRoute = () => {
//   return isAuthenticated() ? 
//     <Outlet />
//    : 
//     <Navigate to="/" replace />
  
// }

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/join" element={<Join />} />

        {/* <Route element={<PrivateRoute />}> */}
          <Route path="/welcome" element={<Login />} />
        {/* </Route> */}
      </Routes>
    </Router>
  )
}

const root = document.getElementById('root');
ReactDOM.createRoot(root).render(<App />);

reportWebVitals();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
